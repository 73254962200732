import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from '@undataforum/gatsby-theme-theme-ui';
import WebLinksIcon from '../../src/components/web-links-icon';
import MdxLayout from '../../src/components/mdx-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      color: 'primary',
      mt: -4,
      mb: -2
    }} mdxType="Box">
  <WebLinksIcon height={128} mdxType="WebLinksIcon" />
    </Box>
    <p>{`This section provides links to various initiatives and resources provided by
agencies of the UN system and beyond, including the regional economic and social
commissions as well as partners from business sector, academia and civil
society.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      